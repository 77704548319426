import React from 'react'
import { Flex, Box } from 'reflexbox'
import Logo from 'ui-components/lib/components/Logo'
import Header from 'ui-components/lib/components/Header'
import logo from 'ui-components/assets/bonuz_logo_colorido.svg'

const Unauthorized = () => {
  return (
    <Flex justifyContent={'center'} alignItems={'center'} textAlign={'center'} mt={'25%'}>
      <Box>
        <Logo src={logo} alt={'logo Bonuz'} width={150} m={'auto'} />
        <Header title={'Assine um plano para ter acesso a esse serviço.'} />
      </Box>
    </Flex>
  )
}

export default Unauthorized
