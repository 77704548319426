import React from 'react'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { Flex } from 'reflexbox'
import { useHistory } from 'react-router-dom'
import './style.css'
import paperAirplane from '../../assets/icons/paperAirplane.svg'
import mailbox from '../../assets/icons/mailbox.svg'
import chevron from '../../assets/icons/chevron.svg'

const ContractManagement = (props) => {
  const history = useHistory()
  const navigationLinks = [{ label: 'Início', onClick: () => history.push('/dashboard') }, { label: 'Gestão Contratual' }]

  return (
    <Flex width={1} justifyContent="center" flexDirection="column" className="service">
      <Flex width={1} mb={3} className="breadCrumb">
        <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
      </Flex>
      <Flex width={1} flexDirection="column">
        <div className='my-services'>
          <h4>Gestão Contratual</h4>
          <div className='items'>
            <div className='item'>
              <img src={mailbox} alt='' />
              <h5>Documentos</h5>
              <div className='text'>
                Anexe contratos e você poderá ter a gestão do prazo de vencimento, edite quando achar necessário e seja notificado por e-mail.
              </div>
              <button type='button' onClick={() => history.push('/dashboard/gestao-contratual/documents')}> Ver documentos <img src={chevron}  alt='' /></button>
            </div>
            <div className='item'>
              <img src={paperAirplane}  alt='' />
              <h5>Alertas</h5>
              <div className='text'>
                Apresentação de todos os resultados dos serviços que você solicitou recentemente.
              </div>
              <button type='button' onClick={() => history.push('/dashboard/gestao-contratual/alerts')}> Criar alertas <img src={chevron}  alt='' /></button>
            </div>
          </div>
        </div>
      </Flex>
    </Flex>
  )
}

export default ContractManagement
