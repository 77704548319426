import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import { demandsQueries, walletQueries } from '../../../graphql/queries'
import Modal from '@mui/material/Modal'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { ChatDemandFixed, ChipStatusVariants, Loading, PurchaseBNZ } from '../../../components'
import './DemandDetails.css'
import ChatDemand from '../../../components/ChatDemand'
import RefuseModal from '../../../components/Modal/Proposals/RefuseModal'
import AcceptModal from '../../../components/Modal/Proposals/AcceptModal'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import RatingModal from '../../../components/Modal/RatingModal'
import { Col, Row } from 'react-bootstrap'
import { Rating } from '@mui/material'
import { ReactComponent as Anexar } from '../../../assets/icons/anexar.svg'
import Switch from '@mui/material/Switch'
import { useFormik } from 'formik'
import EvaluateDemand from '../../../models/EvaluateDemand'
import WarningIcon from '@mui/icons-material/Warning'
import DownloadIcon from '@mui/icons-material/Download'
import DoneIcon from '@mui/icons-material/Done'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import UploadIcon from '@mui/icons-material/Upload'
import Dropzone from '../../../components/Dropzone'
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { Flex } from 'reflexbox'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'

alertify.set("notifier", "position", "top-right");

const DemandDetailsV2 = () => {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { showMessage } = useAlertStack()
  const { push } = useHistory()
  const timestampToDate = require('timestamp-to-date')
  const [openChatModal, setOpenChatModal] = useState(false)
  const [isProposal, setIsProposal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [acceptDemandBidId, setAcceptDemandBidId] = useState('')
  const [refuseDemandBidId, setRefuseDemandBidId] = useState('')
  const [demandId, setDemandId] = useState('')
  const [modalType, setModalType] = useState('')
  const [openRefuseModal, setOpenRefuseModal] = useState(false)
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const [openRatingModal, setRatingModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [servicePrice, setServicePrice] = useState('')
  const [clientSubscription, setClientSubscription] = useState('')
  const [view, setView] = useState('demand-chat')
  const [rate, setRating] = useState(0)
  const [documentPending, setDocumentsPending] = useState([])
  const [documentWaiting, setDocumentsWaiting] = useState([])
  const [documentDone, setDocumentsDone] = useState([])
  const [documentPendingApprove, setDocumentPendingApprove] = useState([])
  const [documentsSending, setDocumentsSending] = useState([])
  const [requests, setRequests] = useState([])

  useEffect(() => {
    if (location.search.slice(1) === 'proposal') setIsProposal(true)
  }, [location])

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal',
    },
  })

  const { loading: demandLoading, data: demandData } = useQuery(demandsQueries.GET_DEMAND, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { refetch: refetchDemands } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_estimating',
    },
  })

  const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

  useQuery(demandsQueries.GET_DEMAND_REQUESTS, {
    variables: {
      id,
    },
    onCompleted(response) {
      setRequests(response.getRequest);
    }
  })

  useEffect(() => {
    const items = requests?.filter((item) => item.status !== 'refused')
    setDocumentsPending(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => ['pending', 'refused'].includes(item.status))
    )
    setDocumentsWaiting(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => item.status === 'waiting_lawyer_acceptance')
    )
    setDocumentsDone(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => item.status === 'done')
    )
    const documents = items?.filter((item) => item.status === 'pending' && item.debitBNZ)
    let pendingApprove = null
    if (documents?.length > 0) {
      pendingApprove = documents[0]
    }
    setDocumentPendingApprove(pendingApprove)
  }, [requests])

  const [reproveDemandBid, { loading: reproveDemandBidLoading }] = useMutation(demandsMutations.REPROVE_DEMAND_BID, {
    variables: {
      bidId: refuseDemandBidId,
    },
    onCompleted() {
      setOpenRefuseModal(false)
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenRefuseModal(false)
      showMessage({
        title: 'Erro ao reprovar a proposta',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [approveDemandBid, { loading: approveDemandBidLoading }] = useMutation(demandsMutations.APPROVE_DEMAND_BID, {
    variables: {
      bidId: acceptDemandBidId,
    },
    onCompleted() {
      setOpenAcceptModal(false)
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenAcceptModal(false)
      showMessage({
        title: 'Erro ao aceitar a proposta',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [approveRequest, { loading: loadingApproveRequest }] = useMutation(demandsMutations.APPROVE_REQUEST, {
    onCompleted() {
      showMessage({
        title: 'Aprovação',
        message: 'Sua aprovação foi enviada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    onError({ graphQLErrors }){
      showMessage({
        title: 'Erro ao aprovar solicitação',
        message: graphQLErrors[0].extensions.response.body || 'tente novamente',
        color: 'danger',
        position: 'bottom-left',
        time:2000
      })
    }
  })

  const [refuseRequest, { loading: loadingRefuseRequest }] = useMutation(demandsMutations.REFUSE_REQUEST, {
    onCompleted() {
      showMessage({
        title: 'Recusa',
        message: 'Sua recusa foi enviada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    onError({ graphQLErrors }){
      showMessage({
        title: 'Erro ao recusar solicitação',
        message: graphQLErrors[0].extensions.response.body || 'tente novamente',
        color: 'danger',
        position: 'bottom-left',
        time:2000
      })
    }
  })

  const handleRefuseRequest = async (request) => {
    alertify.confirm('Confirmação', 'Deseja realmente recusar a solicitação de documentos?', async () => {
      try {
        await refuseRequest({
          variables: {
            demandId: request.demandId,
            requestId: request._id
          }
        })
        const updatedRequests = requests.map(item => {
          if (item._id === request._id) {
            item.status = 'refused'
          }
          return item
        })
        setRequests(updatedRequests)
      } catch (error) {
      }
    }, null).set({labels:{ok:'Sim', cancel: 'Não'}})
  }

  const handleApproveRequest = async (request) => {
    alertify.confirm('Confirmação', 'Deseja realmente aprovar a solicitação de documentos?', async () => {
      try {
        await approveRequest({
          variables: {
            demandId: request.demandId,
            requestId: request._id
          }
        })
        const updatedRequests = requests.map(item => {
          if (item._id === request._id) {
            item.status = 'approved'
          }
          return item
        })
        setRequests(updatedRequests)
      } catch (error) {
      }
    }, null).set({labels:{ok:'Sim', cancel: 'Não'}})
  }

  const handleRefuseDemandBid = useCallback(() => {
    reproveDemandBid()
  }, [reproveDemandBid])

  const handleAcceptDemandBid = useCallback(() => {
    approveDemandBid()
  }, [approveDemandBid])

  const handleOpenAcceptModal = useCallback((id) => {
    setAcceptDemandBidId(id)
    setOpenAcceptModal(!openAcceptModal)
  }, []) //eslint-disable-line

  const handleOpenRefuseModal = useCallback((id) => {
    setRefuseDemandBidId(id)
    setOpenRefuseModal(!openRefuseModal)
  }, []) //eslint-disable-line

  const handleConfirmationModal = useCallback((type) => {
    setDemandId(id)
    setModalType(type)
    setOpenConfirmationModal(!openConfirmationModal)
  }, []) //eslint-disable-line

  const handleChatModal = () => {
    setOpenChatModal(!openChatModal)
  }

  const handleCloseRatingModal = () => {
    setRatingModal(false)
    push('/dashboard/serviços')
  }

  const openModal = useCallback((price) => {
    setServicePrice(price)
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    refetchMyWallet()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (dataClientSubscription) {
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  function handleRating(rate) {
    setRating(rate)
    handleSubmitFormik()
  }

  useQuery(demandsQueries.GET_DEMAND_EVALUATION, {
    variables: {
      demandId: demandData?.demand?._id,
    },
    onCompleted(response) {
      setRating(response.getDemandEvaluation.evaluation)
    },
  })

  const [evaluateDemand] = useMutation(demandsMutations.EVALUATE_DEMAND, {
    onCompleted() {
      showMessage({
        title: 'Avaliação enviada',
        message: 'Sua avaliação foi enviada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    onError() {
      showMessage({
        title: 'Avaliação não enviada',
        message: 'Houve um erro ao tentar enviar sua avaliação. Tente novamente',
        color: 'error',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [sendFileRequestDocument] = useMutation(demandsMutations.SEND_FILE_REQUEST_DOCUMENT, {
    onCompleted() {
      showMessage({
        title: 'Arquivo Enviado',
        message: 'Seu arquivo foi enviada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    onError() {
      showMessage({
        title: 'Arquivo não enviado',
        message: 'Houve um erro ao tentar enviar seu arquivo. Tente novamente',
        color: 'error',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new EvaluateDemand(),
    onSubmit: (values) => {
      evaluateDemand({
        variables: {
          demandId: demandData?.demand?._id,
          lawyerId: demandData?.demand?.lawyer?._id,
          evaluation: rate,
          lawyerEvaluation: rate,
        },
      })
    },
  })

  function handleSubmitFormik() {
    formik.handleSubmit()
  }

  const setUploadDocument = async (document, isLoading) => {
    if (isLoading) {
      setDocumentsSending((prevDocuments) => [...prevDocuments, document._id])
    }
  }

  const setFilesHandler = async (sendFiles, document) => {
    const variables = {
      demandId: document?.demandId,
      requestId: document?.requestId,
      documentId: document?._id,
      files: sendFiles.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
        attachment: '',
        name,
        randomFileName,
        mimeType,
        extension,
        size,
      }))
    }
    try {
      const { data: { sendFileRequestDocument: { files } } = {} } = await sendFileRequestDocument({
        variables
      })
      document.status = 'waiting_lawyer_acceptance'
      document.filesDelivery = files

      const updatedRequests = requests.map(request => {
        request.documents = request.documents.map(item => item._id === document._id ? document : item)
        return request;
      });
      setRequests(updatedRequests)
    } catch (error) {
    } finally {
      setDocumentsSending((prevDocuments) => prevDocuments.filter((item) => item !== document._id))
    }
  }

  if (demandLoading) return <Loading />

  const navigationLinks = [
    { label: 'Início', onClick: () => history.push('/dashboard') },
    { label: 'Serviços', onClick: () => history.push('/dashboard/serviços') },
    { label: 'Demandas', onClick: () => history.push('/dashboard/serviços/demands') },
    { label: 'Demanda' }
  ]

  return (
    <>
      <Flex width={1} justifyContent="center" flexDirection="column" className="service">
        <Flex width={1} mb={3} className="breadCrumb">
          <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
        </Flex>
      </Flex>
      <div style={{paddingLeft: '2.5%', paddingRight: '2.5%'}}>
        <Row id={'demand-info-main-container'}>
          {/*Sidebar*/}
          <Col xs={3} style={{ paddingRight: '0px' }}>
            <div id="demand-sidebar">
              <div>
                <div className="title">
                  <h3>
                    <strong>{demandData?.demand?.fullDescription}</strong>
                  </h3>
                </div>
                <div className="options-container">
                  <Row>
                    <Col>
                      <button onClick={() => setView('demand-chat')}>Conversas</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button onClick={() => setView('demand-request')}>
                        <span>Documentos solicitados</span> {documentPending?.length > 0 ? <WarningIcon sx={{ color: 'orange' }}></WarningIcon> : null}
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button onClick={() => setView('demand-info')}>Informações da demanda</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/*<button onClick={()=>window.location.href = '/dashboard/bonuz-sign/create'}>*/}
                      <button onClick={() => alert('Em breve')}>Assinatura eletrônica</button>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="subtitle">
                  <h5>Fluxo da demanda</h5>
                </div>
                <div className="timeline-container">
                  <div>
                    {demandData?.demand?.demandInteractionsHistory.length > 0
                      ? demandData?.demand?.demandInteractionsHistory?.filter(item => item.status).map((interaction, counter) => {
                          return (
                            <Row key={counter} className={'timeline-item'}>
                              <Col xs={4} className="border-0 m-0 p-0">
                                <span>{timestampToDate(interaction.createdAt, 'dd/MM/yyyy')}</span>
                              </Col>
                              <Col xs={8} className="border-0 m-0 p-0 left-bordered">
                                <span className={'bullet'}>&#8226;</span>
                                <span>
                                  {' '}
                                  <ChipStatusVariants noStyle={true} status={interaction.status} style={{ marginRight: '1px' }} />
                                </span>
                                <span>
                                  {interaction.documents.length > 0
                                    ? interaction.documents.map((document, counter) => (
                                        <Anexar
                                          className="attach-button"
                                          onClick={() => window.open(document.fileURL, '_blank')}
                                          style={{ marginRight: '1px' }}
                                        />
                                      ))
                                    : ''}
                                </span>
                              </Col>
                            </Row>
                          )
                        })
                      : ''}
                    <Row className={'timeline-item'}>
                      <Col xs={4} className="border-0 m-0 p-0">
                        <span>{demandData?.demand?.finalDate ? timestampToDate(demandData?.demand.finalDate, 'dd/MM/yyyy') : '-'}</span>
                      </Col>
                      <Col xs={8} className="border-0 m-0 p-0 left-bordered">
                        <span className={'bullet'}>&#8226;</span>
                        <span>Prazo de entrega da demanda</span>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="rating">
                  <p>
                    O que achou do trabalho de <br /> <strong>{demandData?.demand?.lawyer?.displayAs || '-'}:</strong>
                  </p>
                  <Rating
                    value={rate}
                    onChange={(event, newValue) => {
                      handleRating(newValue)
                    }}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={9}>
            {/*Topbar*/}
            <Row className={'demand-v2-topbar'}>
              <Col xs={2}>
                <h6>Prazo final</h6>
                <p>
                  <strong>{demandData?.demand?.finalDate ? timestampToDate(demandData?.demand.finalDate, 'dd/MM/yyyy') : '-'}</strong>
                </p>
              </Col>
              <Col xs={2}>
                <h6>Responsável</h6>
                <p>{demandData?.demand?.lawyer?.displayAs || '-'}</p>
              </Col>
              <Col xs={2}>
                <h6>Status</h6>
                <p>
                  <ChipStatusVariants status={demandData?.demand.status} noStyle={true} />
                </p>
              </Col>
              <Col xs={2}>
                <h6>Apoio da Bonuz</h6>
                <Switch checked={true} />
              </Col>
              <Col xs={1}>
                <h6>Participantes</h6>
                <p> - </p>
              </Col>
              <Col xs={3}>
                <div className="dg-modal">
                  {demandData?.demand.status === 'waiting_approval' && demandData?.demand.status !== 'in_auction' ? (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        style={{ backgroundColor: '#44E47A', marginRight: '10px' }}
                        onClick={() => handleConfirmationModal('revisar')}
                      >
                        Revisar
                      </button>
                      <button className="btn" style={{ backgroundColor: '#7683F6' }} onClick={() => handleConfirmationModal('concluir')}>
                        Concluir
                      </button>
                    </div>
                  ) : demandData?.demand.status === 'waiting_estimate_approval' && demandData?.demand.status !== 'in_auction' ? (
                    <>
                      {walletData.wallet.balance >= demandData?.demand?.price ? (
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn"
                            style={{ backgroundColor: '#44E47A', marginRight: '10px' }}
                            onClick={() => handleConfirmationModal('recusar')}
                          >
                            Recusar
                          </button>
                          <button className="btn" style={{ backgroundColor: '#7683F6' }} onClick={() => handleConfirmationModal('aceitar')}>
                            Aceitar
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div>
                            <strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa demanda.</strong>
                          </div>
                          <button className="btn" style={{ backgroundColor: '#44E47A' }} onClick={() => openModal(demandData?.demand?.price)}>
                            {clientSubscription ? 'Pagamento' : 'Comprar BNZ'}
                          </button>
                        </div>
                      )}
                    </>
                  ) : demandData?.demand.status === 'pending' && demandData?.demand.status !== 'in_auction' ? (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        style={{ backgroundColor: '#44E47A', marginRight: '10px' }}
                        onClick={() => handleConfirmationModal('cancelar')}
                      >
                        Cancelar
                      </button>
                    </div>
                  ) : isProposal ? (
                    walletData.wallet.balance > demandData?.demand?.demandBids[0]?.price ? (
                      <div className="d-flex justify-content-center">
                        <button className="accept-button" onClick={() => handleOpenAcceptModal(demandData?.demand?.demandBids[0]?._id)}>
                          Aceitar
                        </button>
                        <button className="denied-button mx-3" onClick={() => handleOpenRefuseModal(demandData?.demand?.demandBids[0]?._id)}>
                          Recusar
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <div>
                          <strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa proposta.</strong>
                        </div>
                        <button
                          className="btn"
                          style={{ backgroundColor: '#44E47A' }}
                          onClick={() => openModal(demandData?.demand?.demandBids[0]?.price)}
                        >
                          {clientSubscription ? 'Pagamento' : 'Comprar BNZ'}
                        </button>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </Col>
            </Row>
            {/*Pagecontent*/}
            <Row>
              {view === 'demand-chat' && (
                <ChatDemandFixed
                  handleClose={handleChatModal}
                  openModal={openChatModal}
                  demandId={id}
                  demandLawyer={demandData?.demand?.lawyer || ''}
                />
              )}
              {view === 'demand-info' && (
                <>
                  <div className="demand-obs">
                    <h3>Observações/Comentário</h3>
                    <p>
                      {demandData?.demand?.demandInteractionsHistory[0]?.comment !== undefined
                        ? demandData?.demand?.demandInteractionsHistory[0]?.comment
                        : ''}
                    </p>
                  </div>
                  <div className="demand-awnsers">
                    <h3>Detalhes da demanda</h3>
                    {demandData?.demand?.demandAnswers.length > 0 ? (
                      demandData?.demand?.demandAnswers.map((answer, counter) => {
                        return (
                          <div>
                            <p>
                              <b>{answer.description}</b>
                            </p>
                            <p>
                              Resposta: <b>{answer.answer}</b>
                            </p>
                            <br />
                          </div>
                        )
                      })
                    ) : (
                      <p>Não há questionário preenchido</p>
                    )}
                  </div>
                </>
              )}
              {view === 'demand-request' && (
                <div>
                  <Row id="demand-requests">
                    {documentPending.length > 0 && (
                      <Row className="demand-request">
                        <Row className="demand-request-header">
                          <Col xs={8}>
                            <span className="title">
                              Documentos pendentes <WarningIcon sx={{ color: 'orange' }}></WarningIcon>
                            </span>
                          </Col>
                          <Col xs={4}>
                            {documentPendingApprove && (
                              <>
                                <Row>
                                  <Col xs={12}>
                                    <span className="debit-add">Débito adicional</span>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={6}>
                                    <span className="value-bnz">{documentPendingApprove.valueBNZ}BNZs</span>
                                  </Col>
                                  <Col xs={3}>
                                    <button
                                      type="button"
                                      className="btn" 
                                      style={{ backgroundColor: '#9997A0', color: '#FFFFFF' }}
                                      onClick={() => handleRefuseRequest(documentPendingApprove)}
                                      disabled={loadingRefuseRequest || loadingApproveRequest}
                                    >
                                      {loadingRefuseRequest || loadingApproveRequest ? 
                                        (<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
                                      : ('Recusar')}
                                    </button>
                                  </Col>
                                  <Col xs={3}>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{ backgroundColor: '#49A96A', color: '#FFFFFF' }}
                                      onClick={() => handleApproveRequest(documentPendingApprove)}
                                      disabled={loadingRefuseRequest || loadingApproveRequest}
                                    >
                                      {loadingRefuseRequest || loadingApproveRequest ? 
                                        (<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
                                      : ('Aprovar')}
                                    </button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                        {documentPending.map((item) => (
                          <div className="demand-request-content">
                            <div className="demand-request-item">
                              <Col xs={12} className="demand-request-itemn-document">
                                <span className="demand-request-item-document-title">{item.description}</span>
                                {item.status === 'refused' && <span className="demand-request-item-document-comment"> (Motivo Recusa: {item.comment})</span>}
                              </Col>
                              <Col xs={12} className="demand-request-item-footer">
                                <Row>
                                  <Col xs={9}>
                                    {item.filesRequest.length > 0 && (
                                      <>
                                        <Col xs={12}>
                                          <span className="demand-request-item-footer-model">Modelo</span>
                                        </Col>
                                        <Col xs={12}>
                                          <button
                                            type="button"
                                            className="demand-request-btn-grey"
                                            onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                          >
                                            <DownloadIcon /> {item.filesRequest[0].name}
                                          </button>
                                        </Col>
                                      </>
                                    )}
                                  </Col>
                                  <Col xs={3} className="demand-request-item-footer-button">
                                    {!documentPendingApprove && (
                                      <button
                                        className="demand-request-btn-blue"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          let fileHandler = document.getElementById(item._id)
                                          fileHandler.click()
                                        }}
                                        disabled={
                                          (documentsSending && documentsSending?.includes(item._id)) ||
                                          (item.reqjuestDebitBNZ && item.requestStatus === 'pending')
                                        }
                                      >
                                        {documentsSending && documentsSending?.includes(item._id) ? (
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                          <>
                                            <UploadIcon /> Enviar Documento
                                          </>
                                        )}
                                      </button>
                                    )}
                                    <Dropzone
                                      multiple={false}
                                      onLoad={(isLoading) => setUploadDocument(item, isLoading)}
                                      id={item._id}
                                      onComplete={(file) => setFilesHandler(file, item)}
                                      classe="dropzone-hide"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </div>
                        ))}
                      </Row>
                    )}
                    {documentWaiting.length > 0 && (
                      <Row className="demand-request">
                        <Row className="demand-request-header">
                          <Col xs={8}>
                            <span className="title">
                              Documentos aguardando aprovação <HourglassBottomIcon sx={{ color: 'orange' }}></HourglassBottomIcon>
                            </span>
                          </Col>
                          <Col xs={4}></Col>
                        </Row>
                        {documentWaiting.map((item) => (
                          <div className="demand-request-content">
                            <div className="demand-request-item">
                              <Col xs={12} className="demand-request-itemn-document">
                                <span className="demand-request-item-document-title">{item.description}</span>
                              </Col>
                              <Col xs={12} className="demand-request-item-footer">
                                <Row>
                                  <Col xs={9}>
                                    {item.filesRequest.length > 0 && (
                                      <>
                                        <Col xs={12}>
                                          <span className="demand-request-item-footer-model">Modelo</span>
                                        </Col>
                                        <Col xs={12}>
                                          <button
                                            type="button"
                                            className="demand-request-btn-grey"
                                            onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                          >
                                            <DownloadIcon /> {item.filesRequest[0].name}
                                          </button>
                                        </Col>
                                      </>
                                    )}
                                  </Col>
                                  <Col xs={3} className="demand-request-item-footer-button">
                                    {item?.filesDelivery && item?.filesDelivery?.length > 0 && (
                                      <button
                                        type="button"
                                        className="demand-request-btn-black" style={{ marginRight: '5px' }}
                                        onClick={() => window.open(item.filesDelivery[0].fileURL, '_blank')}
                                      >
                                        <DownloadIcon /> {item.filesDelivery[0].name}
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </div>
                        ))}
                      </Row>
                    )}
                    {documentDone.length > 0 && (
                      <Row className="demand-request">
                        <Row className="demand-request-header">
                          <Col xs={8}>
                            <span className="title">
                              Documentos concluídos <DoneIcon sx={{ color: 'green' }}></DoneIcon>
                            </span>
                          </Col>
                          <Col xs={4}></Col>
                        </Row>
                        {documentDone.map((item) => (
                          <div className="demand-request-content">
                            <div className="demand-request-item">
                              <Col xs={12} className="demand-request-itemn-document">
                                <span className="demand-request-item-document-title">{item.description}</span>
                              </Col>
                              <Col xs={12} className="demand-request-item-footer">
                                <Row>
                                  <Col xs={9}>
                                    {item.filesRequest.length > 0 && (
                                      <>
                                        <Col xs={12}>
                                          <span className="demand-request-item-footer-model">Modelo</span>
                                        </Col>
                                        <Col xs={12}>
                                          <button
                                            type="button"
                                            className="demand-request-btn-grey"
                                            onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                          >
                                            <DownloadIcon /> {item.filesRequest[0].name}
                                          </button>
                                        </Col>
                                      </>
                                    )}
                                  </Col>
                                  <Col xs={3} className="demand-request-item-footer-button">
                                    {item?.filesDelivery && item?.filesDelivery?.length > 0 && (
                                      <button
                                        type="button"
                                        className="demand-request-btn-black" style={{ marginRight: '5px' }}
                                        onClick={() => window.open(item.filesDelivery[0].fileURL, '_blank')}
                                      >
                                        <DownloadIcon /> {item.filesDelivery[0].name}
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </div>
                          </div>
                        ))}
                      </Row>
                    )}
                    {documentPending?.length === 0 && documentWaiting?.length === 0 && documentDone?.length === 0 && (
                      'Nenhuma solicitação encontrada'
                    )}
                  </Row>
                </div>
              )}
            </Row>
          </Col>
        </Row>
        <ChatDemand handleClose={handleChatModal} openModal={openChatModal} demandId={id} demandLawyer={demandData?.demand?.lawyer || ''} />
        <AcceptModal
          openModal={openAcceptModal}
          handleClose={() => setOpenAcceptModal(false)}
          action={handleAcceptDemandBid}
          loading={approveDemandBidLoading}
        />
        <RefuseModal
          openModal={openRefuseModal}
          handleClose={() => setOpenRefuseModal(false)}
          action={handleRefuseDemandBid}
          loading={reproveDemandBidLoading}
        />
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModal()
          }}
          disableAutoFocus
          sx={{ zIndex: '9999' }}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModal()
            }}
            isJp={clientSubscription || false}
            servicePrice={servicePrice}
          />
        </Modal>
        <ConfirmationModal
          openModal={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          type={modalType}
          id={demandId}
          openRating={() => setRatingModal(true)}
        />
        <RatingModal
          openModal={openRatingModal}
          handleClose={() => handleCloseRatingModal()}
          demandId={id}
          lawyerId={demandData?.demand?.lawyer?._id}
        />
      </div>
    </>
  )
}

export default DemandDetailsV2
